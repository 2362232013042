// this is state *outside* the component which is normally frowned upon,
// but in this case the purpose is to ensure the initial page load will see
// the same state as the server render during state hydration
// once this flag is set to `false` subsequent mounts of the hook will use
// an initial state that reads session storage synchronously - this results

import { useCallback, useEffect, useMemo, useState } from 'react'

// in a less 'glitchy' initial mount
let isInitialMount = true

function getSessionStorageItem(key: string): string | null | undefined {
  if (isInitialMount) {
    return undefined
  }
  return (
    (typeof window !== 'undefined' && window.sessionStorage?.getItem(key)) ||
    null
  )
}

// the data is returned as `undefined` during server rendering and the initial page mount
// otherwise the data is returned as `null` if there is no stored value
export function useSessionStorage<T = unknown>(
  key: string
): [T | null | undefined, (state: T | null) => void] {
  const [state, setState] = useState<string | null | undefined>(() =>
    getSessionStorageItem(key)
  )
  useEffect(() => {
    isInitialMount = false
    setState(getSessionStorageItem(key))
  }, [key])
  useEffect(() => {
    if (typeof state === 'string') {
      window.sessionStorage?.setItem(key, state)
    } else if (state === null) {
      window.sessionStorage?.removeItem(key)
    }
  }, [key, state])
  const data = useMemo<T | null>(
    () => (state ? JSON.parse(state) : state),
    [state]
  )
  const setData = useCallback(
    (data: T | null) => setState(data === null ? null : JSON.stringify(data)),
    [setState]
  )
  return [data, setData]
}
